
import { throttle } from "lodash";
import {
  communicationMapActions,
  communicationMapState
} from "@/store/modules/communication";
const qs = require("qs");
import Vue from "vue";

export default Vue.extend({
  components: {
    SendgridEmailStatusIndicator: () =>
      import("./SendgridEmailStatusIndicator.vue"),
    EmailSengridEvents: () => import("./EmailSendgridEvents.vue")
  },
  props: {
    communication: {
      type: Object
    },
    filterByEvent: {
      type: String
    },
    filterByEmail: {
      type: String
    }
  },
  mounted(): void {
    this.queryCommunicationRecipients();
  },
  data(): any {
    return {
      loading: false,
      recipients: [],
      activitiesData: { data: [], links: { pages: [], totalRecords: 0 } },
      throttledQueryRecipients: throttle(
        (this as any).queryCommunicationRecipients,
        2000
      ),
      currentPage: 1,
      maxNumberOfPagers: 7,

      offset: 0,
      limit: 10
    };
  },
  methods: {
    ...communicationMapActions(["getCommunicationRecipients"]),
    headerCellClassName(): string {
      return "font-medium text-black";
    },
    getEventCount(activities: any, event: any): number {
      return activities.filter((activity: any) => activity.data.event === event)
        .length;
    },
    async queryCommunicationRecipients(overideQuery = null): Promise<void> {
      if (this.loading) return;
      this.loading = true;
      try {
        const query = overideQuery || this.queryData;
        if (!query.__limit) {
          query["__limit"] = this.limit;
        }
        if (!query.__offset) {
          query["__offset"] = this.offset;
        }
        const response = await this.getCommunicationRecipients({
          id: this.communicationId,
          query
        });
        if (response && response.data) {
          this.activitiesData = response;
        }
      } catch (error) {
        this.$appNotifyError("Failed to fetch communication recipients data");
        console.error(error);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    prevClickHandler(): void {
      if (this.currentPage === 1) return;
      this.currentPage = this.currentPage - 1;
    },
    paginationPageChanged(pageNumber: number): void {
      this.currentPage = pageNumber;
      const page: any =
        this.activitiesData.links && this.activitiesData.links.pages
          ? this.activitiesData.links.pages.find(
              (page: any) => page.page === pageNumber
            )
          : null;
      if (page) {
        let queryString = page.url.split("?")[1];
        const queryObject = qs.parse(queryString);
        this.limit = queryObject.__limit;
        this.offset = queryObject.__offset;
        this.queryCommunicationRecipients(queryObject);
        //make request with query
      }
    },
    goToProfile(id: string) {
      this.$router.push(`/admin/users/${id}`).catch(() => {});
    }
  },
  computed: {
    ...communicationMapState(["makingApiRequest"]),
    queryData(): Record<string, any> {
      const query: Record<string, any> = {};
      if (this.filterByEvent.length > 0) {
        query.event = this.filterByEvent;
      }
      if (this.filterByEmail.length > 0) {
        query.email = this.filterByEmail;
      }
      return query;
    },
    communicationId(): string {
      return this.$route.params.communicationId;
    }
  },
  watch: {
    filterByEvent: {
      immediate: false,
      handler() {
        this.offset = 0;
        this.throttledQueryRecipients();
      }
    },
    filterByEmail: {
      immediate: false,
      handler() {
        this.offset = 0;
        this.throttledQueryRecipients();
      }
    }
  }
});
